// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeamCell_logoIcon__HIQoY {
  width: 40px;
  position: relative;
  height: 40px;
  object-fit: cover;
  padding: 8px;
  background-color: white;

}
.TeamCell_joined__6YmOY,
.TeamCell_name__8OZbj {
  position: absolute;
  left: 0;
}
.TeamCell_joined__6YmOY {
  top: 0;
  font-weight: 600;
}
.TeamCell_name__8OZbj {
  top: 34px;
  color: #b7b7b7;
}
.TeamCell_texts__nk1yb {
  width: 89px;
  position: relative;
  height: 55px;
}
.TeamCell_content__xP4CQ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.TeamCell_teamCell__YAh8k {
  width: 380px;
  background-color: #191e24;
  max-width: 100%;
  height: 109px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  min-width: 360px;
  text-align: left;
  font-size: 17px;
  color: #fff;
  font-family: "Lexend Deca";
}

.TeamCell_link__qDZMF {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Keeps the link text color consistent */
}
`, "",{"version":3,"sources":["webpack://./src/components/TeamCell.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,uBAAuB;;AAEzB;AACA;;EAEE,kBAAkB;EAClB,OAAO;AACT;AACA;EACE,MAAM;EACN,gBAAgB;AAClB;AACA;EACE,SAAS;EACT,cAAc;AAChB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB,EAAE,0BAA0B;EACjD,cAAc,EAAE,yCAAyC;AAC3D","sourcesContent":[".logoIcon {\n  width: 40px;\n  position: relative;\n  height: 40px;\n  object-fit: cover;\n  padding: 8px;\n  background-color: white;\n\n}\n.joined,\n.name {\n  position: absolute;\n  left: 0;\n}\n.joined {\n  top: 0;\n  font-weight: 600;\n}\n.name {\n  top: 34px;\n  color: #b7b7b7;\n}\n.texts {\n  width: 89px;\n  position: relative;\n  height: 55px;\n}\n.content {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 16px;\n}\n\n.teamCell {\n  width: 380px;\n  background-color: #191e24;\n  max-width: 100%;\n  height: 109px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n  justify-content: center;\n  padding: 20px;\n  box-sizing: border-box;\n  min-width: 360px;\n  text-align: left;\n  font-size: 17px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n\n.link {\n  text-decoration: none; /* Removes the underline */\n  color: inherit; /* Keeps the link text color consistent */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoIcon": `TeamCell_logoIcon__HIQoY`,
	"joined": `TeamCell_joined__6YmOY`,
	"name": `TeamCell_name__8OZbj`,
	"texts": `TeamCell_texts__nk1yb`,
	"content": `TeamCell_content__xP4CQ`,
	"teamCell": `TeamCell_teamCell__YAh8k`,
	"link": `TeamCell_link__qDZMF`
};
export default ___CSS_LOADER_EXPORT___;
