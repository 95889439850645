// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeamDetail_logoIcon__c0m\\+7 {
  width: 187px;
  position: relative;
  height: 68.6px;
}
.TeamDetail_navigationbar__2pAH8 {
  align-self: stretch;
  background-color: #191e25;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 32px;
}
.TeamDetail_kader__3g79w {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.TeamDetail_kaderWrapper__qRwKs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1200px;
}
.TeamDetail_season20242025__3eIFq {
  align-self: stretch;
  position: relative;
  font-size: 26px;
}
.TeamDetail_spielplanParent__\\+xQLZ,
.TeamDetail_teamDetail__eMEql {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.TeamDetail_spielplanParent__\\+xQLZ {
  align-items: flex-start;
  gap: 10px;
  max-width: 1200px;
}
.TeamDetail_teamDetail__eMEql {
  position: relative;
  background-color: #0a141b;
  align-items: center;
  gap: 50px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/pages/TeamDetail.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;AACA;;EAEE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,uBAAuB;EACvB,SAAS;EACT,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".logoIcon {\n  width: 187px;\n  position: relative;\n  height: 68.6px;\n}\n.navigationbar {\n  align-self: stretch;\n  background-color: #191e25;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  padding: 10px 32px;\n}\n.kader {\n  align-self: stretch;\n  position: relative;\n  font-weight: 600;\n}\n.kaderWrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  max-width: 1200px;\n}\n.season20242025 {\n  align-self: stretch;\n  position: relative;\n  font-size: 26px;\n}\n.spielplanParent,\n.teamDetail {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.spielplanParent {\n  align-items: flex-start;\n  gap: 10px;\n  max-width: 1200px;\n}\n.teamDetail {\n  position: relative;\n  background-color: #0a141b;\n  align-items: center;\n  gap: 50px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoIcon": `TeamDetail_logoIcon__c0m+7`,
	"navigationbar": `TeamDetail_navigationbar__2pAH8`,
	"kader": `TeamDetail_kader__3g79w`,
	"kaderWrapper": `TeamDetail_kaderWrapper__qRwKs`,
	"season20242025": `TeamDetail_season20242025__3eIFq`,
	"spielplanParent": `TeamDetail_spielplanParent__+xQLZ`,
	"teamDetail": `TeamDetail_teamDetail__eMEql`
};
export default ___CSS_LOADER_EXPORT___;
