// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Matchday_friday__ElSw2 {
  font-weight: 600;
}
.Matchday_august__ChXC6 {
  font-weight: 300;
}
.Matchday_date__YWH8E,
.Matchday_time__Kx7\\+d {
  position: relative;
}
.Matchday_time__Kx7\\+d {
  font-weight: 600;
  text-align: right;
}
.Matchday_headliner__AMFAK {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Matchday_matchday__RxRlG {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  text-align: left;
  font-size: 20px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/components/Matchday.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;;EAEE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".friday {\n  font-weight: 600;\n}\n.august {\n  font-weight: 300;\n}\n.date,\n.time {\n  position: relative;\n}\n.time {\n  font-weight: 600;\n  text-align: right;\n}\n.headliner {\n  align-self: stretch;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n.matchday {\n  width: 100%;\n  max-width: 1200px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 15px;\n  text-align: left;\n  font-size: 20px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"friday": `Matchday_friday__ElSw2`,
	"august": `Matchday_august__ChXC6`,
	"date": `Matchday_date__YWH8E`,
	"time": `Matchday_time__Kx7+d`,
	"headliner": `Matchday_headliner__AMFAK`,
	"matchday": `Matchday_matchday__RxRlG`
};
export default ___CSS_LOADER_EXPORT___;
