// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Events_aktuell__sRLBF {
  position: relative;
  font-weight: 600;
}
.Events_title__KpNDf {
  align-self: stretch;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.Events_image2Icon__n1wlA {
  width: 102.3px;
  position: relative;
  height: 88.5px;
  object-fit: cover;
}
.Events_eventcell__\\+dEUi {
  flex: 1 1;
  background-color: #191e25;
  height: 179px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 15px;
  box-sizing: border-box;
  min-width: 150px;
  max-width: 180px;
  max-height: 180px;
}
.Events_cells__N-kl-,
.Events_events__\\+a3Za {
  display: flex;
  justify-content: flex-start;
}
.Events_cells__N-kl- {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
}
.Events_events__\\+a3Za {
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 1200px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/components/Events.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;AAC7B;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,SAAO;EACP,yBAAyB;EACzB,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;;EAEE,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".aktuell {\n  position: relative;\n  font-weight: 600;\n}\n.title {\n  align-self: stretch;\n  height: 36px;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n.image2Icon {\n  width: 102.3px;\n  position: relative;\n  height: 88.5px;\n  object-fit: cover;\n}\n.eventcell {\n  flex: 1;\n  background-color: #191e25;\n  height: 179px;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 16px 15px;\n  box-sizing: border-box;\n  min-width: 150px;\n  max-width: 180px;\n  max-height: 180px;\n}\n.cells,\n.events {\n  display: flex;\n  justify-content: flex-start;\n}\n.cells {\n  align-self: stretch;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: center;\n  gap: 40px;\n}\n.events {\n  width: 100%;\n  overflow: hidden;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 20px;\n  box-sizing: border-box;\n  gap: 20px;\n  max-width: 1200px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aktuell": `Events_aktuell__sRLBF`,
	"title": `Events_title__KpNDf`,
	"image2Icon": `Events_image2Icon__n1wlA`,
	"eventcell": `Events_eventcell__+dEUi`,
	"cells": `Events_cells__N-kl-`,
	"events": `Events_events__+a3Za`
};
export default ___CSS_LOADER_EXPORT___;
