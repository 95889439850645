// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FirebaseButton_button__VMEhz {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
.FirebaseButton_icon__IHc4G,
.FirebaseButton_sizemediumStylecontained__kQeXd {
  position: relative;
  overflow: hidden;
}
.FirebaseButton_icon__IHc4G {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.FirebaseButton_sizemediumStylecontained__kQeXd {
  cursor: pointer;
  border: 0;
  padding: 8px 20px;
  background-color: #457eff;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.FirebaseButton_sizemediumStylecontained__kQeXd:hover {
  background-color: #094db2;
}
`, "",{"version":3,"sources":["webpack://./src/network/Firebase/storage/FirebaseButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;AACA;;EAEE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;AACA;EACE,eAAe;EACf,SAAS;EACT,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;AACV;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".button {\n  position: relative;\n  font-size: 14px;\n  line-height: 22px;\n  font-family: Inter;\n  color: #fff;\n  text-align: center;\n}\n.icon,\n.sizemediumStylecontained {\n  position: relative;\n  overflow: hidden;\n}\n.icon {\n  width: 16px;\n  height: 16px;\n  flex-shrink: 0;\n}\n.sizemediumStylecontained {\n  cursor: pointer;\n  border: 0;\n  padding: 8px 20px;\n  background-color: #457eff;\n  border-radius: 6px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n}\n.sizemediumStylecontained:hover {\n  background-color: #094db2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `FirebaseButton_button__VMEhz`,
	"icon": `FirebaseButton_icon__IHc4G`,
	"sizemediumStylecontained": `FirebaseButton_sizemediumStylecontained__kQeXd`
};
export default ___CSS_LOADER_EXPORT___;
