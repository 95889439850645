// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  line-height: normal;
  min-height: 100vh;
  min-width: 100%;
  background-color: #0a141b;
}
html {
  scroll-behavior: smooth;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAGA;EACE,SAAS;EACT,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500;600&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap\");\nbody {\n  margin: 0;\n  line-height: normal;\n  min-height: 100vh;\n  min-width: 100%;\n  background-color: #0a141b;\n}\nhtml {\n  scroll-behavior: smooth;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
