// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Impressium_navigationbarIcon__ZzTgB {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 90px;
  flex-shrink: 0;
}
.Impressium_sterreichischerKleinfeldFub__uH7Q5 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.Impressium_impressum__r08PH {
  align-self: stretch;
  position: relative;
  font-size: 26px;
}
.Impressium_impressium__Rb-1q,
.Impressium_sterreichischerKleinfeldFubParent__iX1zq {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.Impressium_sterreichischerKleinfeldFubParent__iX1zq {
  align-items: flex-start;
  gap: 10px;
  max-width: 1200px;
}
.Impressium_impressium__Rb-1q {
  position: relative;
  background-color: #0a141b;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  gap: 50px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/pages/Impressium.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;AACA;;EAEE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,uBAAuB;EACvB,SAAS;EACT,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".navigationbarIcon {\n  align-self: stretch;\n  position: relative;\n  max-width: 100%;\n  overflow: hidden;\n  height: 90px;\n  flex-shrink: 0;\n}\n.sterreichischerKleinfeldFub {\n  align-self: stretch;\n  position: relative;\n  font-weight: 600;\n}\n.impressum {\n  align-self: stretch;\n  position: relative;\n  font-size: 26px;\n}\n.impressium,\n.sterreichischerKleinfeldFubParent {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.sterreichischerKleinfeldFubParent {\n  align-items: flex-start;\n  gap: 10px;\n  max-width: 1200px;\n}\n.impressium {\n  position: relative;\n  background-color: #0a141b;\n  align-items: center;\n  padding: 10px;\n  box-sizing: border-box;\n  gap: 50px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigationbarIcon": `Impressium_navigationbarIcon__ZzTgB`,
	"sterreichischerKleinfeldFub": `Impressium_sterreichischerKleinfeldFub__uH7Q5`,
	"impressum": `Impressium_impressum__r08PH`,
	"impressium": `Impressium_impressium__Rb-1q`,
	"sterreichischerKleinfeldFubParent": `Impressium_sterreichischerKleinfeldFubParent__iX1zq`
};
export default ___CSS_LOADER_EXPORT___;
