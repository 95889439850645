// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/banner@3x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Banner_logoIcon__eXbOX {
  width: 187px;
  position: relative;
  height: 68.6px;
}
.Banner_experienceTheWorld__8pTch {
  margin: 0;
}
.Banner_experienceTheWorldContainer__W6qWQ {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.Banner_experienceTheWorldOfWinnerParent__eENEE {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  min-width: 300px;
}
.Banner_banner__mSOMT,
.Banner_logoParent__i1dis,
.Banner_overlay__Ri4y7 {
  display: flex;
  align-items: center;
}
.Banner_logoParent__i1dis {
  flex: 1 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Banner_banner__mSOMT,
.Banner_overlay__Ri4y7 {
  justify-content: center;
}
.Banner_overlay__Ri4y7 {
  align-self: stretch;
  background-color: rgba(10, 20, 27, 0.6);
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 40px;
}
.Banner_banner__mSOMT {
  width: 100%;
  flex-direction: column;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 1200px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/components/Banner.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;AAClB;AACA;;;EAGE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,SAAO;EACP,mBAAmB;EACnB,eAAe;EACf,8BAA8B;AAChC;AACA;;EAEE,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,uCAAuC;EACvC,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,sBAAsB;EACtB,yDAA4C;EAC5C,sBAAsB;EACtB,4BAA4B;EAC5B,wBAAwB;EACxB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".logoIcon {\n  width: 187px;\n  position: relative;\n  height: 68.6px;\n}\n.experienceTheWorld {\n  margin: 0;\n}\n.experienceTheWorldContainer {\n  align-self: stretch;\n  position: relative;\n  font-weight: 600;\n}\n.experienceTheWorldOfWinnerParent {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  gap: 24px;\n  min-width: 300px;\n}\n.banner,\n.logoParent,\n.overlay {\n  display: flex;\n  align-items: center;\n}\n.logoParent {\n  flex: 1;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n.banner,\n.overlay {\n  justify-content: center;\n}\n.overlay {\n  align-self: stretch;\n  background-color: rgba(10, 20, 27, 0.6);\n  flex-direction: row;\n  flex-wrap: wrap;\n  padding: 30px 40px;\n}\n.banner {\n  width: 100%;\n  flex-direction: column;\n  background-image: url(/public/banner@3x.png);\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: top;\n  max-width: 1200px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoIcon": `Banner_logoIcon__eXbOX`,
	"experienceTheWorld": `Banner_experienceTheWorld__8pTch`,
	"experienceTheWorldContainer": `Banner_experienceTheWorldContainer__W6qWQ`,
	"experienceTheWorldOfWinnerParent": `Banner_experienceTheWorldOfWinnerParent__eENEE`,
	"banner": `Banner_banner__mSOMT`,
	"logoParent": `Banner_logoParent__i1dis`,
	"overlay": `Banner_overlay__Ri4y7`
};
export default ___CSS_LOADER_EXPORT___;
