// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogDetail_blogDetail__FlJdb {
  font-family: Inter;
  color: #fff;
}

.BlogDetail_content__OBQfm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BlogDetail_blogImage__lkbwA {
  width: 100%;
  margin: 2% 10%;
  max-width: 900px;
}

.BlogDetail_blogText__eVVHv {
  width: 100%;
  width: 100%;
  max-width: 800px;
}

.BlogDetail_blogText__eVVHv h4 {
  margin-bottom: 10px;
  font-size: 0.9em;
  color: white;
}

.BlogDetail_blogText__eVVHv p {
  font-size: 1.1em;
  line-height: 1.6;
}

.BlogDetail_created__u1OqX {
  font-size: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/pages/BlogDetail.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".blogDetail {\n  font-family: Inter;\n  color: #fff;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.blogImage {\n  width: 100%;\n  margin: 2% 10%;\n  max-width: 900px;\n}\n\n.blogText {\n  width: 100%;\n  width: 100%;\n  max-width: 800px;\n}\n\n.blogText h4 {\n  margin-bottom: 10px;\n  font-size: 0.9em;\n  color: white;\n}\n\n.blogText p {\n  font-size: 1.1em;\n  line-height: 1.6;\n}\n\n.created {\n  font-size: 1.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blogDetail": `BlogDetail_blogDetail__FlJdb`,
	"content": `BlogDetail_content__OBQfm`,
	"blogImage": `BlogDetail_blogImage__lkbwA`,
	"blogText": `BlogDetail_blogText__eVVHv`,
	"created": `BlogDetail_created__u1OqX`
};
export default ___CSS_LOADER_EXPORT___;
