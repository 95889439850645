// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlayerCell_playerCell__RlS\\+w {
  background-color: #191e24;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 20px;
  box-sizing: border-box;
  gap: 20px;
  text-align: left;
  font-size: 20px;
  color: #b7b7b7;
  font-family: "Lexend Deca";
}

.PlayerCell_logoIcon__dlsf8 {
  width: 95px;
  height: 125px;
  object-fit: cover;
}

.PlayerCell_textContainer__kUMQO {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.PlayerCell_name__GRddB {
  font-weight: bold;
  color: #ffffff;
}

.PlayerCell_info__\\+VE8h {
  font-size: 16px;
  color: #b7b7b7;
}

.PlayerCell_position__gtblD {
  font-size: 16px;
  color: #b7b7b7;
}

.PlayerCell_eligibilityOK__xnlDg {
  font-size: 16px;
  color: green;
}

.PlayerCell_eligibilityPending__qYjYF {
  font-size: 16px;
  color: orange;
}

.PlayerCell_eligibilityNo__RAVSX {
  font-size: 16px;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/PlayerCell.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,QAAQ;AACV;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,eAAe;EACf,UAAU;AACZ","sourcesContent":[".playerCell {\n  background-color: #191e24;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  padding: 10px 20px;\n  box-sizing: border-box;\n  gap: 20px;\n  text-align: left;\n  font-size: 20px;\n  color: #b7b7b7;\n  font-family: \"Lexend Deca\";\n}\n\n.logoIcon {\n  width: 95px;\n  height: 125px;\n  object-fit: cover;\n}\n\n.textContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 5px;\n}\n\n.name {\n  font-weight: bold;\n  color: #ffffff;\n}\n\n.info {\n  font-size: 16px;\n  color: #b7b7b7;\n}\n\n.position {\n  font-size: 16px;\n  color: #b7b7b7;\n}\n\n.eligibilityOK {\n  font-size: 16px;\n  color: green;\n}\n\n.eligibilityPending {\n  font-size: 16px;\n  color: orange;\n}\n\n.eligibilityNo {\n  font-size: 16px;\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playerCell": `PlayerCell_playerCell__RlS+w`,
	"logoIcon": `PlayerCell_logoIcon__dlsf8`,
	"textContainer": `PlayerCell_textContainer__kUMQO`,
	"name": `PlayerCell_name__GRddB`,
	"info": `PlayerCell_info__+VE8h`,
	"position": `PlayerCell_position__gtblD`,
	"eligibilityOK": `PlayerCell_eligibilityOK__xnlDg`,
	"eligibilityPending": `PlayerCell_eligibilityPending__qYjYF`,
	"eligibilityNo": `PlayerCell_eligibilityNo__RAVSX`
};
export default ___CSS_LOADER_EXPORT___;
