// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LigaWrapper.module.css */
.LigaItemCell_ligawrapper__C5pJa {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  padding: 0px 30px;
}

/* LigaItemCell.module.css */
.LigaItemCell_wappenIcon__iJuaw {
  width: 77.4px;
  position: relative;
  height: 86.3px;
  object-fit: cover;
}

.LigaItemCell_wiener1__UAxhw {
  margin-bottom: 0;
}

.LigaItemCell_wiener__fFdgX {
  font-family: inherit;
  font-size: inherit;
  padding-left: 20px;
}

.LigaItemCell_liga__\\+Ug-q,
.LigaItemCell_wiener__fFdgX {
  margin: 0;
}

.LigaItemCell_name__3kn-\\+ {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}

.LigaItemCell_bundeslandCell__A6pWw {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 8.93px;
  background-color: #191e25;
  width: 148.8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 19.3px 7.4px;
  box-sizing: border-box;
  gap: 14.9px;
  min-width: 148.76px;
}

.LigaItemCell_ligaitemcell__phgF2 {
  width: 148.8px;
  height: 180px;
  text-align: center;
  font-size: 14.9px;
  color: #fff;
  font-family: "Lexend Deca";
  position: relative; /* This ensures that the absolute positioning inside works correctly */
}
`, "",{"version":3,"sources":["webpack://./src/components/LigaItemCell.module.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,iBAAiB;AACnB;;AAEA,4BAA4B;AAC5B;EACE,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;;EAEE,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,MAAM;EACN,SAAS;EACT,OAAO;EACP,qBAAqB;EACrB,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,sBAAsB;EACtB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,0BAA0B;EAC1B,kBAAkB,EAAE,sEAAsE;AAC5F","sourcesContent":["/* LigaWrapper.module.css */\n.ligawrapper {\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n  gap: 16px;\n  padding: 0px 30px;\n}\n\n/* LigaItemCell.module.css */\n.wappenIcon {\n  width: 77.4px;\n  position: relative;\n  height: 86.3px;\n  object-fit: cover;\n}\n\n.wiener1 {\n  margin-bottom: 0;\n}\n\n.wiener {\n  font-family: inherit;\n  font-size: inherit;\n  padding-left: 20px;\n}\n\n.liga,\n.wiener {\n  margin: 0;\n}\n\n.name {\n  align-self: stretch;\n  position: relative;\n  font-weight: 600;\n}\n\n.bundeslandCell {\n  position: absolute;\n  height: 100%;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  border-radius: 8.93px;\n  background-color: #191e25;\n  width: 148.8px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 19.3px 7.4px;\n  box-sizing: border-box;\n  gap: 14.9px;\n  min-width: 148.76px;\n}\n\n.ligaitemcell {\n  width: 148.8px;\n  height: 180px;\n  text-align: center;\n  font-size: 14.9px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n  position: relative; /* This ensures that the absolute positioning inside works correctly */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ligawrapper": `LigaItemCell_ligawrapper__C5pJa`,
	"wappenIcon": `LigaItemCell_wappenIcon__iJuaw`,
	"wiener1": `LigaItemCell_wiener1__UAxhw`,
	"wiener": `LigaItemCell_wiener__fFdgX`,
	"liga": `LigaItemCell_liga__+Ug-q`,
	"name": `LigaItemCell_name__3kn-+`,
	"bundeslandCell": `LigaItemCell_bundeslandCell__A6pWw`,
	"ligaitemcell": `LigaItemCell_ligaitemcell__phgF2`
};
export default ___CSS_LOADER_EXPORT___;
