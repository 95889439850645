// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Scoreitems_div__JlLMW {
  position: relative;
  font-weight: 600;
}
.Scoreitems_scoreitem__QXPc4,
.Scoreitems_scoreitem1__nBL5u {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13px;
  box-sizing: border-box;
}
.Scoreitems_scoreitem1__nBL5u {
  right: 0;
  left: 50%;
}
.Scoreitems_scoreitems__y09nY {
  width: 82px;
  height: 47px;
  text-align: left;
  font-size: 27px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/components/Scoreitems.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;;EAEE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,MAAM;EACN,UAAU;EACV,SAAS;EACT,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,QAAQ;EACR,SAAS;AACX;AACA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".div {\n  position: relative;\n  font-weight: 600;\n}\n.scoreitem,\n.scoreitem1 {\n  position: absolute;\n  height: 100%;\n  width: 50%;\n  top: 0;\n  right: 50%;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 13px;\n  box-sizing: border-box;\n}\n.scoreitem1 {\n  right: 0;\n  left: 50%;\n}\n.scoreitems {\n  width: 82px;\n  height: 47px;\n  text-align: left;\n  font-size: 27px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div": `Scoreitems_div__JlLMW`,
	"scoreitem": `Scoreitems_scoreitem__QXPc4`,
	"scoreitem1": `Scoreitems_scoreitem1__nBL5u`,
	"scoreitems": `Scoreitems_scoreitems__y09nY`
};
export default ___CSS_LOADER_EXPORT___;
