// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RankingCell_joined__z-1Dj {
  position: absolute;
  top: 4px;
  left: 0;
  font-weight: 600;
}
.RankingCell_logoIcon__C1Bjt {
  width: 40px;
  position: relative;
  height: 29px;
  object-fit: cover;
}
.RankingCell_joined1__-evNO {
  position: relative;
  font-weight: 600;
}
.RankingCell_hometeam__FTJ2X {
  position: absolute;
  top: 0;
  left: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 21px;
  text-align: left;
}
.RankingCell_leffblock__nSTQ9 {
  width: 179px;
  position: relative;
  height: 29px;
}
.RankingCell_statitemParent__F1JJu {
  justify-content: flex-start;
  gap: 19px;
}
.RankingCell_content__6JwJF,
.RankingCell_rankingCell__nBJ-v,
.RankingCell_rightblock__XqdHO,
.RankingCell_statitemParent__F1JJu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.RankingCell_rightblock__XqdHO {
  justify-content: flex-start;
  gap: 28px;
}
.RankingCell_content__6JwJF,
.RankingCell_rankingCell__nBJ-v {
  flex-wrap: wrap;
}
.RankingCell_content__6JwJF {
  flex: 1 1;
  justify-content: space-between;
}
.RankingCell_rankingCell__nBJ-v {
  background-color: #191e24;
  max-width: 100%;
  justify-content: flex-start;
  padding: 5px 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 17px;
  color: #fff;
  font-family: "Lexend Deca";
  align-self: stretch;
}
`, "",{"version":3,"sources":["webpack://./src/components/RankingCell.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,2BAA2B;EAC3B,SAAS;AACX;AACA;;;;EAIE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,2BAA2B;EAC3B,SAAS;AACX;AACA;;EAEE,eAAe;AACjB;AACA;EACE,SAAO;EACP,8BAA8B;AAChC;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,2BAA2B;EAC3B,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,0BAA0B;EAC1B,mBAAmB;AACrB","sourcesContent":[".joined {\n  position: absolute;\n  top: 4px;\n  left: 0;\n  font-weight: 600;\n}\n.logoIcon {\n  width: 40px;\n  position: relative;\n  height: 29px;\n  object-fit: cover;\n}\n.joined1 {\n  position: relative;\n  font-weight: 600;\n}\n.hometeam {\n  position: absolute;\n  top: 0;\n  left: 29px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 21px;\n  text-align: left;\n}\n.leffblock {\n  width: 179px;\n  position: relative;\n  height: 29px;\n}\n.statitemParent {\n  justify-content: flex-start;\n  gap: 19px;\n}\n.content,\n.rankingCell,\n.rightblock,\n.statitemParent {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.rightblock {\n  justify-content: flex-start;\n  gap: 28px;\n}\n.content,\n.rankingCell {\n  flex-wrap: wrap;\n}\n.content {\n  flex: 1;\n  justify-content: space-between;\n}\n.rankingCell {\n  background-color: #191e24;\n  max-width: 100%;\n  justify-content: flex-start;\n  padding: 5px 20px;\n  box-sizing: border-box;\n  text-align: center;\n  font-size: 17px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n  align-self: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"joined": `RankingCell_joined__z-1Dj`,
	"logoIcon": `RankingCell_logoIcon__C1Bjt`,
	"joined1": `RankingCell_joined1__-evNO`,
	"hometeam": `RankingCell_hometeam__FTJ2X`,
	"leffblock": `RankingCell_leffblock__nSTQ9`,
	"statitemParent": `RankingCell_statitemParent__F1JJu`,
	"content": `RankingCell_content__6JwJF`,
	"rankingCell": `RankingCell_rankingCell__nBJ-v`,
	"rightblock": `RankingCell_rightblock__XqdHO`
};
export default ___CSS_LOADER_EXPORT___;
