// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BundeslanderSelectionRow_ligen__3TM5H {
  width: 1260px;
  position: relative;
  font-weight: 600;
  display: none;
}

.BundeslanderSelectionRow_bundeslanderSelectionRow__NbHH1 {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 21px;
  max-width: 1200px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: 'Lexend Deca';
}

.BundeslanderSelectionRow_scrollContainer__gq1yb {
  display: flex;
  overflow-x: auto;
  gap: 23px;
  width: 100%;
  padding: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/BundeslanderSelectionRow.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,SAAS;EACT,WAAW;EACX,eAAe;AACjB","sourcesContent":[".ligen {\n  width: 1260px;\n  position: relative;\n  font-weight: 600;\n  display: none;\n}\n\n.bundeslanderSelectionRow {\n  width: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0 20px;\n  box-sizing: border-box;\n  gap: 21px;\n  max-width: 1200px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: 'Lexend Deca';\n}\n\n.scrollContainer {\n  display: flex;\n  overflow-x: auto;\n  gap: 23px;\n  width: 100%;\n  padding: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ligen": `BundeslanderSelectionRow_ligen__3TM5H`,
	"bundeslanderSelectionRow": `BundeslanderSelectionRow_bundeslanderSelectionRow__NbHH1`,
	"scrollContainer": `BundeslanderSelectionRow_scrollContainer__gq1yb`
};
export default ___CSS_LOADER_EXPORT___;
