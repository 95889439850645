// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bundeslander_wappenIcon__CXpwQ {
  width: 52px;
  height: 58px;
  object-fit: cover;
}

.Bundeslander_name__Rtfhd {
  font-weight: 600;
}

.Bundeslander_bundesland__JIpGU {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px;
  background-color: #191e25;
  height: 121px;
  padding: 13px 5px;
  box-sizing: border-box;
  min-width: 100px;
  text-decoration: none;
  color: inherit;
  font-size: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Bundeslander.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,qBAAqB;EACrB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".wappenIcon {\n  width: 52px;\n  height: 58px;\n  object-fit: cover;\n}\n\n.name {\n  font-weight: 600;\n}\n\n.bundesland {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: column;\n  gap: 10px;\n  border-radius: 6px;\n  background-color: #191e25;\n  height: 121px;\n  padding: 13px 5px;\n  box-sizing: border-box;\n  min-width: 100px;\n  text-decoration: none;\n  color: inherit;\n  font-size: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wappenIcon": `Bundeslander_wappenIcon__CXpwQ`,
	"name": `Bundeslander_name__Rtfhd`,
	"bundesland": `Bundeslander_bundesland__JIpGU`
};
export default ___CSS_LOADER_EXPORT___;
