// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Statitem2_key__Dv2ds,
.Statitem2_value__7\\+-Bm {
  position: relative;
}

.Statitem2_key__Dv2ds {
  font-weight: 600;
  color: white;
}

.Statitem2_value__7\\+-Bm {
  align-self: stretch;
  font-size: 18px;
  font-weight: 400;
  color: #b7b7b7;
  line-height: 33px;
  white-space: pre-line; /* Allows text to wrap and preserve whitespace */
  word-wrap: break-word; /* Break long words if necessary */
  overflow-wrap: break-word; /* Ensures the text doesn't overflow */
}

.Statitem2_statitem__Y5QR7 {
  background-color: #191e25;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  gap: 10px;
  min-width: 390px;
  text-align: left;
  font-size: 20px;
  color: #fff;
  font-family: "Lexend Deca";
}

.Statitem2_statitem__Y5QR7 ul {
  list-style-type: decimal; /* Ensures numbering */
  margin-left: 20px; /* Indentation for list */
}

.Statitem2_statitem__Y5QR7 li {
  margin-bottom: 10px; /* Space between list items */
  list-style-type: none; /* Hides default bullets */
}
`, "",{"version":3,"sources":["webpack://./src/components/Statitem2.module.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,qBAAqB,EAAE,gDAAgD;EACvE,qBAAqB,EAAE,kCAAkC;EACzD,yBAAyB,EAAE,sCAAsC;AACnE;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB,EAAE,sBAAsB;EAChD,iBAAiB,EAAE,yBAAyB;AAC9C;;AAEA;EACE,mBAAmB,EAAE,6BAA6B;EAClD,qBAAqB,EAAE,0BAA0B;AACnD","sourcesContent":[".key,\n.value {\n  position: relative;\n}\n\n.key {\n  font-weight: 600;\n  color: white;\n}\n\n.value {\n  align-self: stretch;\n  font-size: 18px;\n  font-weight: 400;\n  color: #b7b7b7;\n  line-height: 33px;\n  white-space: pre-line; /* Allows text to wrap and preserve whitespace */\n  word-wrap: break-word; /* Break long words if necessary */\n  overflow-wrap: break-word; /* Ensures the text doesn't overflow */\n}\n\n.statitem {\n  background-color: #191e25;\n  max-width: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  padding: 10px;\n  box-sizing: border-box;\n  gap: 10px;\n  min-width: 390px;\n  text-align: left;\n  font-size: 20px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n\n.statitem ul {\n  list-style-type: decimal; /* Ensures numbering */\n  margin-left: 20px; /* Indentation for list */\n}\n\n.statitem li {\n  margin-bottom: 10px; /* Space between list items */\n  list-style-type: none; /* Hides default bullets */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"key": `Statitem2_key__Dv2ds`,
	"value": `Statitem2_value__7+-Bm`,
	"statitem": `Statitem2_statitem__Y5QR7`
};
export default ___CSS_LOADER_EXPORT___;
