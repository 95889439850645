// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeagueOverview_logoIcon__p1GYr {
  width: 187px;
  position: relative;
  height: 68.6px;
}
.LeagueOverview_leagueOverview__ccb7Q,
.LeagueOverview_navigationbar__F-RVd {
  display: flex;
  flex-direction: column;

}
.LeagueOverview_navigationbar__F-RVd {
  align-self: stretch;
  background-color: #191e25;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 32px;
}
.LeagueOverview_leagueOverview__ccb7Q {
  width: 100%;
  /*height: 100vh;*/
  position: relative;
  background-color: #0a141b;
  display: flex;              /* Ensure the container uses Flexbox */
  flex-direction: column;     /* Align children vertically */
  align-items: center;        /* Align items horizontally at the center */
  justify-content: space-between; /* Distribute spacing evenly */
  gap: 50px;                  /* Gap between items */
  height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LeagueOverview.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB;AACA;;EAEE,aAAa;EACb,sBAAsB;;AAExB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,uBAAuB;EACvB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa,eAAe,sCAAsC;EAClE,sBAAsB,MAAM,8BAA8B;EAC1D,mBAAmB,SAAS,2CAA2C;EACvE,8BAA8B,EAAE,8BAA8B;EAC9D,SAAS,mBAAmB,sBAAsB;EAClD,mBAAmB;AACrB","sourcesContent":[".logoIcon {\n  width: 187px;\n  position: relative;\n  height: 68.6px;\n}\n.leagueOverview,\n.navigationbar {\n  display: flex;\n  flex-direction: column;\n\n}\n.navigationbar {\n  align-self: stretch;\n  background-color: #191e25;\n  overflow: hidden;\n  align-items: flex-start;\n  justify-content: center;\n  padding: 10px 32px;\n}\n.leagueOverview {\n  width: 100%;\n  /*height: 100vh;*/\n  position: relative;\n  background-color: #0a141b;\n  display: flex;              /* Ensure the container uses Flexbox */\n  flex-direction: column;     /* Align children vertically */\n  align-items: center;        /* Align items horizontally at the center */\n  justify-content: space-between; /* Distribute spacing evenly */\n  gap: 50px;                  /* Gap between items */\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoIcon": `LeagueOverview_logoIcon__p1GYr`,
	"leagueOverview": `LeagueOverview_leagueOverview__ccb7Q`,
	"navigationbar": `LeagueOverview_navigationbar__F-RVd`
};
export default ___CSS_LOADER_EXPORT___;
