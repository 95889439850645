// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigationbar_logoIcon__jwxCB {
  width: 187px;
  position: relative;
  height: 68.6px;
}
.Navigationbar_navigationbar__qypDA {
  align-self: stretch;
  background-color: #191e25;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 32px;
}

.Navigationbar_wrapper__h45St { max-width: 1200px}`, "",{"version":3,"sources":["webpack://./src/components/Navigationbar.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA,gCAAW,iBAAiB","sourcesContent":[".logoIcon {\n  width: 187px;\n  position: relative;\n  height: 68.6px;\n}\n.navigationbar {\n  align-self: stretch;\n  background-color: #191e25;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  padding: 10px 32px;\n}\n\n.wrapper { max-width: 1200px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoIcon": `Navigationbar_logoIcon__jwxCB`,
	"navigationbar": `Navigationbar_navigationbar__qypDA`,
	"wrapper": `Navigationbar_wrapper__h45St`
};
export default ___CSS_LOADER_EXPORT___;
