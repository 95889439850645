// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Aktuell_aktuell1__DxCVI {
  position: relative;
  font-weight: 600;
}
.Aktuell_aktuell__56EXH,
.Aktuell_title__UARCK {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.Aktuell_title__UARCK {
  align-self: stretch;
  height: 36px;
  flex-direction: row;
}
.Aktuell_aktuell__56EXH {
  width: 100%;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/components/Aktuell.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;;EAEE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".aktuell1 {\n  position: relative;\n  font-weight: 600;\n}\n.aktuell,\n.title {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n.title {\n  align-self: stretch;\n  height: 36px;\n  flex-direction: row;\n}\n.aktuell {\n  width: 100%;\n  flex-direction: column;\n  gap: 20px;\n  max-width: 1200px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aktuell1": `Aktuell_aktuell1__DxCVI`,
	"aktuell": `Aktuell_aktuell__56EXH`,
	"title": `Aktuell_title__UARCK`
};
export default ___CSS_LOADER_EXPORT___;
