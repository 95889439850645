// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlayerDetail_kader__W0z7G,
.PlayerDetail_navigationbarIcon__BUCeT {
  align-self: stretch;
  position: relative;
}
.PlayerDetail_navigationbarIcon__BUCeT {
  max-width: 100%;
  overflow: hidden;
  height: 90px;
  flex-shrink: 0;
}
.PlayerDetail_kader__W0z7G {
  font-weight: 600;
}
.PlayerDetail_kaderWrapper__jH\\+bU {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1200px;
}
.PlayerDetail_season20232024__p5GvO {
  align-self: stretch;
  position: relative;
  font-size: 26px;
}
.PlayerDetail_playerDetail__fVBKn,
.PlayerDetail_spielerStatistikParent__JCoIT {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.PlayerDetail_spielerStatistikParent__JCoIT {
  align-items: flex-start;
  gap: 10px;
  max-width: 1200px;
}
.PlayerDetail_playerDetail__fVBKn {
  position: relative;
  background-color: #0a141b;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  gap: 50px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/pages/PlayerDetail.module.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;AACA;;EAEE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,uBAAuB;EACvB,SAAS;EACT,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".kader,\n.navigationbarIcon {\n  align-self: stretch;\n  position: relative;\n}\n.navigationbarIcon {\n  max-width: 100%;\n  overflow: hidden;\n  height: 90px;\n  flex-shrink: 0;\n}\n.kader {\n  font-weight: 600;\n}\n.kaderWrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  max-width: 1200px;\n}\n.season20232024 {\n  align-self: stretch;\n  position: relative;\n  font-size: 26px;\n}\n.playerDetail,\n.spielerStatistikParent {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.spielerStatistikParent {\n  align-items: flex-start;\n  gap: 10px;\n  max-width: 1200px;\n}\n.playerDetail {\n  position: relative;\n  background-color: #0a141b;\n  align-items: center;\n  padding: 10px;\n  box-sizing: border-box;\n  gap: 50px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kader": `PlayerDetail_kader__W0z7G`,
	"navigationbarIcon": `PlayerDetail_navigationbarIcon__BUCeT`,
	"kaderWrapper": `PlayerDetail_kaderWrapper__jH+bU`,
	"season20232024": `PlayerDetail_season20232024__p5GvO`,
	"playerDetail": `PlayerDetail_playerDetail__fVBKn`,
	"spielerStatistikParent": `PlayerDetail_spielerStatistikParent__JCoIT`
};
export default ___CSS_LOADER_EXPORT___;
