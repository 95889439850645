// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Playerslist_playerslist__vSQkC {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Playerslist.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,2DAA2D;EAC3D,SAAS;EACT,uBAAuB;EACvB,8BAA8B;EAC9B,iBAAiB;AACnB","sourcesContent":[".playerslist {\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));\n  gap: 20px;\n  align-items: flex-start;\n  justify-content: space-between;\n  max-width: 1200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playerslist": `Playerslist_playerslist__vSQkC`
};
export default ___CSS_LOADER_EXPORT___;
