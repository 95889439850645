// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Thankyou_blogDetail__hvyLS {
  font-family: Inter;
  color: #fff;
}

.Thankyou_content__Hse\\+k {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Thankyou_blogImage__PsWKb {
  width: 100%;
  margin: 2% 10%;
  max-width: 900px;
}

.Thankyou_blogText__vWVNR {
  width: 100%;
  width: 100%;
  max-width: 800px;
}

.Thankyou_blogText__vWVNR h4 {
  margin-bottom: 10px;
  font-size: 0.9em;
  color: white;
}

.Thankyou_blogText__vWVNR p {
  font-size: 1.1em;
  line-height: 1.6;
}

.Thankyou_created__oATWN {
  font-size: 1.5em;
}

.Thankyou_button__0SJS- {
  background-color: orange;
  font-size: 18px;
  color: black;
  padding: 3px;
}

.Thankyou_content__Hse\\+k {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* This will center the content vertically */
  min-height: 70vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Thankyou.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB,EAAE,4CAA4C;EACrE,gBAAgB;AAClB","sourcesContent":[".blogDetail {\n  font-family: Inter;\n  color: #fff;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.blogImage {\n  width: 100%;\n  margin: 2% 10%;\n  max-width: 900px;\n}\n\n.blogText {\n  width: 100%;\n  width: 100%;\n  max-width: 800px;\n}\n\n.blogText h4 {\n  margin-bottom: 10px;\n  font-size: 0.9em;\n  color: white;\n}\n\n.blogText p {\n  font-size: 1.1em;\n  line-height: 1.6;\n}\n\n.created {\n  font-size: 1.5em;\n}\n\n.button {\n  background-color: orange;\n  font-size: 18px;\n  color: black;\n  padding: 3px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center; /* This will center the content vertically */\n  min-height: 70vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blogDetail": `Thankyou_blogDetail__hvyLS`,
	"content": `Thankyou_content__Hse+k`,
	"blogImage": `Thankyou_blogImage__PsWKb`,
	"blogText": `Thankyou_blogText__vWVNR`,
	"created": `Thankyou_created__oATWN`,
	"button": `Thankyou_button__0SJS-`
};
export default ___CSS_LOADER_EXPORT___;
