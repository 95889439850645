// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LigaWrapper_ligawrapper__QH-Ia {
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
@media (max-width: 768px) {
  .LigaWrapper_ligawrapper__QH-Ia {
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LigaWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;AACX;AACA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".ligawrapper {\n  max-width: 1200px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 10px;\n}\n@media (max-width: 768px) {\n  .ligawrapper {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ligawrapper": `LigaWrapper_ligawrapper__QH-Ia`
};
export default ___CSS_LOADER_EXPORT___;
