// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NationalrankingItem_flagIcon__JuRKI,
.NationalrankingItem_rank__xCprJ {
  position: relative;
}
.NationalrankingItem_rank__xCprJ {
  font-size: 17px;
  font-weight: 600;
}
.NationalrankingItem_flagIcon__JuRKI {
  width: 25.8px;
  height: 14.6px;
  object-fit: cover;
}
.NationalrankingItem_coumtry__sAaK7,
.NationalrankingItem_points__c5YR8 {
  position: relative;
  font-weight: 600;
}
.NationalrankingItem_points__c5YR8 {
  font-weight: 300;
}
.NationalrankingItem_nationalrankingitem__c6DAO {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.7px;
  text-align: left;
  font-size: 13.1px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/components/NationalrankingItem.module.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,cAAc;EACd,iBAAiB;AACnB;AACA;;EAEE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".flagIcon,\n.rank {\n  position: relative;\n}\n.rank {\n  font-size: 17px;\n  font-weight: 600;\n}\n.flagIcon {\n  width: 25.8px;\n  height: 14.6px;\n  object-fit: cover;\n}\n.coumtry,\n.points {\n  position: relative;\n  font-weight: 600;\n}\n.points {\n  font-weight: 300;\n}\n.nationalrankingitem {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 7.7px;\n  text-align: left;\n  font-size: 13.1px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flagIcon": `NationalrankingItem_flagIcon__JuRKI`,
	"rank": `NationalrankingItem_rank__xCprJ`,
	"coumtry": `NationalrankingItem_coumtry__sAaK7`,
	"points": `NationalrankingItem_points__c5YR8`,
	"nationalrankingitem": `NationalrankingItem_nationalrankingitem__c6DAO`
};
export default ___CSS_LOADER_EXPORT___;
